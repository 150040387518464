<template>
    <div>
        <Head :title="'预处理任务分配'" :out="'WaitingDisassembly?active=0'" />
        <div class="content">
            <van-field  v-model="name" readonly label="工序" />
            <van-field  v-model="isSaleOfName" readonly label="是否监销" class="hong" />
            <van-field
                readonly
                clickable
                name="picker"
                v-model="disassembleUserValue"
                label="拆解人员"
                placeholder="点击选择拆解人员"
                @click="UserChange"
                />
            <van-popup v-model="showUser" position="bottom" class="userPopup"  :close-on-click-overlay="false"  :style="{height: '100%',}">
                <van-checkbox-group class="group" v-model="result">
                    <van-checkbox class="checkbox" v-for="item in principalUserList" :key="item.id" :name="item.id">{{item.realName}}</van-checkbox>
                </van-checkbox-group>
                <div class="userBtn">
                    <van-button @click="(showUser = false)">取消</van-button>
                    <van-button type="info" @click.stop="userSubmit">确认</van-button>
                </div>
            </van-popup>
            <van-field
                
                readonly
                clickable
                name="picker"
                v-model="LocationValue"
                label="库位"
                placeholder="点击选择库位"
                @click="LocationChange"
                />
            <van-popup v-model="showKu" position="bottom"  :close-on-click-overlay="false">
                <van-picker
                    show-toolbar
                    :columns="LocationArr"
                    value-key="name"
                    @confirm="onConfirm(2,$event)"
                    @cancel="showKu = false"
                >
                </van-picker>
            </van-popup>

            <van-field
                
                readonly
                clickable
                name="picker"
                v-model="working_weidoo_Value"
                label="绩效统计维度"
                placeholder="点击选择绩效统计维度"
                @click="weiduChange"
                />
            <van-popup v-model="showWei" position="bottom"  :close-on-click-overlay="false">
                <van-picker
                    show-toolbar
                    :columns="weidooArr"
                    value-key="name"
                    @confirm="onConfirm(1,$event)"
                    @cancel="showWei = false"
                >
                </van-picker>
            </van-popup>
            <van-field  v-model="remark"  label="拆解要求" type="textarea" placeholder="请输入拆解要求" />
            <van-field name="switch" label="是否添加工序" v-if="defaultStr == '是'">
                <template #input>
                    <van-switch v-model="checked" />
                </template>
            </van-field>
            <div >
                <allotChai isyuchuli="1" ref="ChaiRef" v-if="checked" />
            </div>
            <div class="btn">
                <van-button type="info"  @click="submit">提交</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import Head from '@/components/Head'
import {Dialog} from 'vant'
import {noPageList,gongwei,yuchuliAdd} from '@/api/publics'
import allotChai from './components/allotChai.vue'
    export default {
        components: {
            Head,allotChai
        },
        data() {
            return {
                name: '预处理',
                disassembleUserValue:"",//人员
                showUser:false,//人员弹窗
                result:[],
                working_weidoo_Value:"",//纬度
                showWei:false,//纬度
                principalUserList:[],//人员
                weidooArr:[],//纬度
                showKu:false,//库位
                LocationValue:"",//库位
                LocationArr:[],//库位
                remark:"",//拆解要求
                checked:false,//添加工序
                working_procedure_station_id:"",
                working_weidoo_id:"",
                defaultStr:'',
            }
        },
        computed: {
            //获取是否监销
            isSaleOfName() {
                let isSaleOf = this.$route.query.is_sale_of
                let str = ''
                if(isSaleOf == 1){
                    str='是'
                }else if(isSaleOf ==2){
                    str='否'
                }else{
                    str='未选择'
                }
                return str 
            },
            listId(){
                return this.$route.query.id || ''
            }
        },
        mounted () {
            this.setUp();
        },
        methods: {
            async submit(){
                let disassembly={}
               
                if(this.checked && this.defaultStr == '是'){
                    
                    let GongXuData = this.$refs.ChaiRef.form
                    disassembly = {
                        disassembleTypeId:GongXuData.disassembleTypeId,
                        disassemblyMethodId:GongXuData.disassemblyMethodId,
                        dismant_batch_number:GongXuData.dismant_batch_number,
                        price:GongXuData.price,
                        purchasingchannels_id:GongXuData.purchasingchannels_id,
                        remark:GongXuData.remark,
                        working_procedure:this.$refs.ChaiRef.arrList,
                        user_json:this.$refs.ChaiRef.form.disassemblyMethodId == 3 ?{}:this.$refs.ChaiRef.disassembleUserId
                    }
                }
                let obj = {
                    disassembly:this.checked?disassembly:undefined,
                    ids:[this.listId],
                    remark:this.remark,//拆解备注
                    user_json: this.disassembleUserId,//拆解人员
                    working_procedure_station_id:this.working_procedure_station_id,//库位
                    working_weidoo_id:this.working_weidoo_id,//纬度
                }
               
                Dialog.confirm({
                title: '提示',
                message: '是否确定分配？',
                })
                .then(async () => {
                    // on confirm
                    try{
                        const {data} = await yuchuliAdd(obj).then(res=>res)
                    this.$toast.success(data.msg)
                    this.$router.push({path:'/WaitingDisassembly',query:{active:0}})
                    }
                    catch{}
                })
                .catch(() => {
                    // on cancel
                });
                   
               

            },
            //选择
            onConfirm(num,val){
                if(num == 1) {
                    // this.form.disassembleTypeId = val.id
                    this.working_weidoo_Value = val.name
                    this.working_weidoo_id = val.id
                    this.showWei = false
                }else if(num == 2){
                    this.LocationValue = val.name
                    this.working_procedure_station_id = val.id
                    this.showKu = false
                }
            },
            async LocationChange(){
                this.showKu = true
                try{
                    const {data} = await gongwei().then(res=>res)
                    this.LocationArr = data.data
                }catch{}

            },
            setUp(){
                this.$http.post('/index.php//setUp/info',{id:25}).then(res=>{
                    if(res.data.code == 0){
                        this.defaultStr = res.data.data.default
                    }else if(res.data.code == -1){
                          this.islogout()
                    }else{
                        this.$toast.fail(res.data.msg)
                    }
                 })
            },  
            async weiduChange(){
                this.showWei = true
                try{
                    const {data} = await noPageList().then(res=>res)
                    this.weidooArr = data.data
                }catch{}
            },  
              //选择拆解人
            UserChange(){
                this.showUser = true
                this.getUserList()
            },  
             //获取人员
             getUserList(){
                this.$http.post('/index.php/index/user/userList',{ type: 'bulkBreaker' }).then(res=>{
                    this.principalUserList = res.data.data
                })
            },
             //拆解人
             userSubmit(){
                this.showUser = false
                var arr = []
                var nameArr = []
                this.result.forEach(i=>{
                    this.principalUserList.forEach(j=>{
                        if(i==j.id){
                            const obj = {
                            'realName': j.realName,
                            'id': j.id
                        }
                            arr.push(obj)
                            nameArr.push(j.realName)
                        }
                    })
                })
                this.disassembleUserValue = nameArr.join(',')
                this.disassembleUserId = arr
               
            },  
        },
    }
</script>

<style>
    .hong .van-field__control{
        color:red
    }
</style>
<style lang="scss" scoped>
.content{
    margin-top: 55px;
}
.userPopup{
        .userBtn{
            text-align: center;
            margin-top: 10px;
            .van-button{
                margin: 10px;
            }
        }
        
        .group{
            text-align: center;
            padding: 20px;
            height: 80vh;
            overflow-y: scroll;
            border-bottom: 1px solid #eee;
            .checkbox{
                margin: 5px 0;
            }
        }
    }
    .btn{
        text-align: center;
        margin-top: 50px;
        .van-button{
            width: 60%;
        }
    }
</style>